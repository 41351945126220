let ROOT_URL = "";
let BASE_URL = "";
let socketConnectionURL = "";
if (process.env.REACT_APP_SERVER?.trim() == "production") {
  ROOT_URL = "https://api.foundationbrickindia.in/api/v1";
  BASE_URL = "https://api.foundationbrickindia.in/api/v1";
  socketConnectionURL = "https://api.foundationbrickindia.in";
} else {
  // ROOT_URL = "http://localhost:8000/api/v1";
  // BASE_URL = "http://staging.alphonic.net.in:8004/api/v1";
  // socketConnectionURL = "http://staging.alphonic.net.in:8004";
  ROOT_URL = "https://beta.api.foundationbrickindia.in/api/v1";
  BASE_URL = "https://beta.api.foundationbrickindia.in/api/v1";
  socketConnectionURL = "https://beta.api.foundationbrickindia.in/api/v1";
}
export { BASE_URL, socketConnectionURL };
export default ROOT_URL;
